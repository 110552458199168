import React, {Component} from 'react';
import "./LifePhilosophy.css"

class LifePhilosophy extends Component {
    render() {
        return (
            <>
                <h1 className = "section-header mb-0"> My life philosophy </h1>
                <div className="card-glassmorphism mt-4">
                    <div className="w-100 life-quote">
                        <p>“Sapere aude”</p>
                        <p>“There are more things in heaven and Earth, Horatio, than are dreamt of in your philosophy”</p>
                        <p>“Always use guanciale in carbonara”</p>
                        <div className="short-line" />
                        <div className="text-center mt-3">
                            Do you recognize the quotes? I have recently opened a <a className="link" href="https://www.goodreads.com/user/show/140849273-giuseppe-steduto">goodreads</a> profile, check it out!
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default LifePhilosophy;