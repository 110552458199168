import logo from './assets/logo_med.png';
import './App.scss';

import {Container, Nav, Navbar} from "react-bootstrap";
import About from "./About";
import WorkExperienceEducation from "./WorkExperience";
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import React, {useCallback} from "react";
import Charts from "./Charts";
import Footer from "./Footer";
import Projects from "./Projects";
import Contact from "./Contact";
import AnchorLink from "react-anchor-link-smooth-scroll";
import LifePhilosophy from "./LifePhilosophy";

function App() {
    const particlesInit = useCallback(async (engine) => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async (container) => {
        await console.log(container);
    }, []);

    const particleOptions = {
        background: {
            color: {
                value: "#16213E",
            },
        },
        style: {
          zIndex: "-1000"
        },
        fpsLimit: 120,
        interactivity: {
            events: {
                onHover: {
                    enable: true,
                    mode: "push",
                },
                resize: true,
            },
            modes: {
                push: {
                    quantity: 4,
                },
                repulse: {
                    distance: 200,
                    duration: 0.4,
                },
            },
        },
        particles: {
            number: {
                value: 15,
            },
            color: {
                value: ["rgba(38,58,108,1)", "#4ca295", "#3db767", "#ff6363"],
            },
            shape: {
                type: "circle",
            },
            opacity: {
                value: 0.8,
            },
            size: {
                value: { min: 50, max: 200 },
            },
            move: {
                enable: true,
                angle: {
                    value: 30,
                    offset: 0,
                },
                speed: {
                    min: 0.2,
                    max: 3,
                },
                directions: "none",
            },
        },
        particles222: {
            color: {
                value: "#ffffff",
            },
            links: {
                color: "#ffffff",
                distance: 150,
                enable: true,
                opacity: 0.5,
                width: 1,
            },
            collisions: {
                enable: true,
            },
            move: {
                directions: "none",
                enable: true,
                outModes: {
                    default: "bounce",
                },
                random: false,
                speed: 3000,
                straight: false,
            },
            number: {
                density: {
                    enable: true,
                    area: 1000,
                },
                value: 50,
            },
            opacity: {
                value: 0.5,
            },
            shape: {
                type: "circle",
            },
            size: {
                value: { min: 1, max: 5 },
            },
        },
        detectRetina: true,
    };

  return (
    <>
        <div className="background">
            <Particles id="tsparticles" init={particlesInit} loaded={particlesLoaded} options={particleOptions}/>
        </div>
        <Navbar fixed="top" expand="md" className="custom-navbar">
            <Container>
                <Navbar.Brand href="#home">
                    <img
                        alt="Giuseppe Steduto"
                        src={logo}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" data-bs-target="responsive-navbar-nav" variant="light" className="navbar-light"/>
                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                    <Nav>
                        <Nav.Link><AnchorLink href='#aboutme' offset='100'>About me</AnchorLink></Nav.Link>
                        <Nav.Link><AnchorLink href='#projects' offset='100'>Projects</AnchorLink></Nav.Link>
                        <Nav.Link><AnchorLink href='#skills' offset='100'>Skills</AnchorLink></Nav.Link>
                        <Nav.Link><AnchorLink href='#contactme' offset='100'>Contact me</AnchorLink></Nav.Link>
                        <Nav.Link href='/blog'>Blog</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        <main className="mt-4">
            <Container>
                <div id="aboutme" style={{marginTop: "5rem"}} ><About /></div>
                <div className="whitespace-divider-smaller"/>
                <WorkExperienceEducation />
                <div className="whitespace-divider" />
                <div id="projects"><Projects /></div>
                <div className="whitespace-divider"/>
                <div id="skills"><Charts /></div>
                <div className="whitespace-divider"/>
                <LifePhilosophy />
                <div className="whitespace-divider"/>
                <div id="contactme"><Contact /></div>
            </Container>
        </main>

        <Footer />
    </>
  );
}

export default App;
