import React, {Component} from 'react';
import { SocialIcon } from 'react-social-icons';

import "./Footer.css"

class Footer extends Component {
    render() {
        return (
            <footer>
                <div className="social-media-container mb-3">
                    <SocialIcon url="https://linkedin.com/in/giuseppe-steduto/" bgColor={"white"} />
                    <SocialIcon url="https://github.com/giuseppe-steduto" bgColor={"white"} />
                    <SocialIcon url="https://instagram.com/giuseppe.steduto" bgColor={"white"} />
                    <SocialIcon url="https://medium.com/@giuseppe.steduto" bgColor={"white"} />
                    <SocialIcon url="https://t.me/giuseppe_steduto" bgColor={"white"} />
                </div>
                -----------
                <p className="text-center text-white">Created with ❤️ by Giuseppe Steduto.</p>
            </footer>
        );
    }
}


export default Footer;