import React, {Component} from 'react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBriefcase, faGraduationCap} from '@fortawesome/free-solid-svg-icons'
import 'react-vertical-timeline-component/style.min.css';
import "./VerticalTimeline.scss"

let styleCard = {
    background: "rgba(22,33,62,0.5)",
    fontFamily: "Inconsolata, sans-serif",
    color: '#fff',
    boxShadow:"0 8px 32px 0 rgba(22,33,62,0.37)",
    backdropFilter:"blur(12px)",
    border: "2px solid rgba( 255, 255, 255, 0.18 )"
}
let styleCardActive = {...styleCard, background: "rgba(255,255,255,0.3)"}
let styleIcon = {...styleCardActive, outline: "4px solid #fff", border: "0px"}


class WorkExperience extends Component {
    render() {
        return (
            <div>
                <h2 className="mt-5" id="work-experience">Work experience</h2>
                <VerticalTimeline layout="1-column-left">
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={styleCardActive}
                        contentArrowStyle={{ borderRight: '0px' }}
                        date="Nov 2022 - present"
                        iconStyle={styleIcon}
                        icon={<FontAwesomeIcon icon={faBriefcase} />}
                    >
                        <h3 className="vertical-timeline-element-title">Grupem - Milan (Italy)</h3>
                        <h4 className="vertical-timeline-element-subtitle">CTO </h4>
                        <p>I designed and created a web application consisting of a user dashboard and a complex relational database, collaborating with an
                            international team of mobile app developers. My work improved the user satisfaction by 300%.</p>
                        <p><em>Web Development: Laravel, PHP, MySQL, ReactJS</em></p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={styleCard}
                        contentArrowStyle={{ borderRight: '0px' }}
                        date="Mar 2023 - Feb 2024"
                        iconStyle={styleIcon}
                        icon={<FontAwesomeIcon icon={faBriefcase} />}
                    >
                        <h3 className="vertical-timeline-element-title">CERN - Geneva (Switzerland)</h3>
                        <h4 className="vertical-timeline-element-subtitle">Software Engineer </h4>
                        <p>I am working on REANA, a platform that allows scientists and researcher from all backgrounds to
                            preserve their analyses, and make them reproducible and reusable.</p>
                        <p><em>Python, Kubernets, Docker</em></p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={styleCard}
                        contentArrowStyle={{ borderRight: '0px solid  #E94560' }}
                        date="Sep 2022 - Jan 2023"
                        iconStyle={styleIcon}
                        icon={<FontAwesomeIcon icon={faBriefcase} />}
                    >
                        <h3 className="vertical-timeline-element-title">Illinois Institute of Technology - Chicago (USA)</h3>
                        <h4 className="vertical-timeline-element-subtitle">Undergraduate Researcher</h4>
                        <p>Automated a computer vision tool for early detection of diabetic retinopathy starting from fluorescein angiography images.</p>
                        <p><em>Computer Vision, MATLAB, Python</em></p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={styleCard}
                        contentArrowStyle={{ borderRight: '0px' }}
                        date="Dec 2021 - Jul 2022"
                        iconStyle={styleIcon}
                        icon={<FontAwesomeIcon icon={faBriefcase} />}
                    >
                        <h3 className="vertical-timeline-element-title">Milan Engineering Student Association - Milan (Italy) </h3>
                        <h4 className="vertical-timeline-element-subtitle">IT Team Leader</h4>
                        <p>Trained and directed an international team composed of 11 persons in building a website with Wordpress and custom PHP plugins to showcase the work of the association.</p>
                        <p><em>Web Development, PHP, Wordpress</em></p>
                    </VerticalTimelineElement>
                </VerticalTimeline>

                <h2 className="mt-5">Education</h2>
                <VerticalTimeline layout="1-column-left">
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={styleCard}
                        contentArrowStyle={{ borderRight: '0px' }}
                        date="2024 - present"
                        iconStyle={styleIcon}
                        icon={<FontAwesomeIcon icon={faGraduationCap} />}
                    >
                        <h3 className="vertical-timeline-element-title">M. Sc. Computer Science </h3>
                        <h4 className="vertical-timeline-element-subtitle">ETH Zurich, Switzerland</h4>
                        <p>
                            Majoring in Artificial Intelligence.
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={styleCard}
                        contentArrowStyle={{ borderRight: '0px' }}
                        date="2020 - 2024"
                        iconStyle={styleIcon}
                        icon={<FontAwesomeIcon icon={faGraduationCap} />}
                    >
                        <h3 className="vertical-timeline-element-title">B. Sc. Computer Science and Engineering</h3>
                        <h4 className="vertical-timeline-element-subtitle">Politecnico di Milano, Italy</h4>
                        <p>
                            I graduated with the maximum mark (110L).
                            Attended courses about Algorithms, Data Structures, Databases, Computer architecture, and so much more! I was also part of many associations.

                            Top 5% of my year and have been awarded the "Best Freshmen" scholarship!
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={styleCard}
                        contentArrowStyle={{ borderRight: '0px' }}
                        date="2022 - 2023"
                        iconStyle={styleIcon}
                        icon={<FontAwesomeIcon icon={faGraduationCap} />}
                    >
                        <h3 className="vertical-timeline-element-title">B. Sc. Computer Engineering - Exchange</h3>
                        <h4 className="vertical-timeline-element-subtitle">Illinois Institute of Technology, Chicago</h4>
                        <p>
                            I won a scholarship from the Institute for International Education to spend one semester in the US!

                            I attended courses on Artificial Intelligence and Electronics, and I was part of the Association for Computer Machinery.
                        </p>
                    </VerticalTimelineElement>
                </VerticalTimeline>
            </div>
        );
    }
}

export default WorkExperience;
