import React, {Component} from 'react';

class ProjectCard extends Component {
    render() {
        return (
            <div className="card-glassmorphism-with-image card-projects">
                <img src={this.props.imgsrc} className="w-100"/>
                <h2 className="mt-3 mb-2">{this.props.title}</h2>
                <p className="mb-4">{this.props.description}</p>
                {(this.props.url) ? <button className="button-project">
                    <a href={this.props.url}>Check it out!</a>
                </button> : ""}
            </div>
        );
    }
}

export default ProjectCard;