import '../App.scss';

import {Button, Card, Container} from "react-bootstrap";
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import React, {useCallback} from "react";
import propic from "../assets/propic_datacentre.jpg";
import {SocialIcon} from "react-social-icons";

function ContactCard() {
  let styleCard = {
    background: "rgba(22,33,62,0.5)",
    fontFamily: "Inconsolata, sans-serif",
    color: '#fff',
    width: 'fit-content',
    margin: "0 auto",
    padding: "1rem",
    boxShadow:"0 8px 32px 0 rgba(22,33,62,0.37)",
    backdropFilter:"blur(12px)",
    border: "2px solid rgba( 255, 255, 255, 0.18 )",
  }

  const particlesInit = useCallback(async (engine) => {
    console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  const particleOptions = {
    background: {
      color: {
        value: "#16213E",
      },
    },
    style: {
      zIndex: "-1000"
    },
    fpsLimit: 120,
    interactivity: {
      events: {
        onHover: {
          enable: true,
          mode: "push",
        },
        resize: true,
      },
      modes: {
        push: {
          quantity: 4,
        },
        repulse: {
          distance: 200,
          duration: 0.4,
        },
      },
    },
    particles: {
      number: {
        value: 15,
      },
      color: {
        value: ["rgba(38,58,108,1)", "#4ca295", "#3db767", "#ff6363"],
      },
      shape: {
        type: "circle",
      },
      opacity: {
        value: 0.8,
      },
      size: {
        value: { min: 50, max: 200 },
      },
      move: {
        enable: true,
        angle: {
          value: 30,
          offset: 0,
        },
        speed: {
          min: 0.2,
          max: 3,
        },
        directions: "none",
      },
    },
    particles222: {
      color: {
        value: "#ffffff",
      },
      links: {
        color: "#ffffff",
        distance: 150,
        enable: true,
        opacity: 0.5,
        width: 1,
      },
      collisions: {
        enable: true,
      },
      move: {
        directions: "none",
        enable: true,
        outModes: {
          default: "bounce",
        },
        random: false,
        speed: 3000,
        straight: false,
      },
      number: {
        density: {
          enable: true,
          area: 1000,
        },
        value: 50,
      },
      opacity: {
        value: 0.5,
      },
      shape: {
        type: "circle",
      },
      size: {
        value: { min: 1, max: 5 },
      },
    },
    detectRetina: true,
  };

  const handleDownloadVCard = () => {
    // Define the vCard content
    const vCardData = `
      BEGIN:VCARD
      VERSION:3.0
      FN:Giuseppe Steduto
      TEL;TYPE=WORK,VOICE:+39-392-061-4414
      EMAIL:giuseppe@steduto.it
      END:VCARD
    `;

    // Create a Blob from the vCard data
    const blob = new Blob([vCardData], { type: 'text/vcard' });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a temporary anchor element and trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = 'contact.vcf';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url); // Clean up the URL
  };

  return (
    <>
      <div className="background">
        <Particles id="tsparticles" init={particlesInit} loaded={particlesLoaded} options={particleOptions}/>
      </div>

      <Container fluid="sm" className="d-grid place-items-center align-items-center vh-100">
        <Card style={styleCard}>
          <Card.Body>
            <div className = "photo-container" style={{maxWidth: "300px"}}>
              <img src = { propic }  alt = "Giuseppe Steduto" />
            </div>
            <h1 className="name-span text-center mt-4">Giuseppe Steduto</h1>
            <h2 className="text-center mb-5">Software Engineer | MSc CS @ ETH Zurich</h2>
            <div className="social-media-container mb-3">
              <SocialIcon url="https://linkedin.com/in/giuseppe-steduto/" bgColor={"white"} />
              <SocialIcon url="https://github.com/giuseppe-steduto" bgColor={"white"} />
              <SocialIcon url="https://instagram.com/giuseppe.steduto" bgColor={"white"} />
              <SocialIcon url="https://t.me/giuseppe_steduto" bgColor={"white"} />
            </div>

            <Button onClick={handleDownloadVCard} className="button-see-what-i-do mt-5 d-block m-auto">Save contact info</Button>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

export default ContactCard;
