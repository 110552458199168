import React, { Component } from 'react';
import { Row, Col } from "react-bootstrap";
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend, } from 'chart.js';
import { Radar } from 'react-chartjs-2';
import skills from "./assets/skills.png";
import badges_json from "./assets/badges_names.json";
import "./Charts.css"

class Charts extends Component {
    render() {
        ChartJS.register(
            RadialLinearScale,
            PointElement,
            LineElement,
            Filler,
            Tooltip,
            Legend
        );

        ChartJS.defaults.color = "#fff";
        ChartJS.defaults.font = {
            family: "Inconsolata",
            size: 14,
            style: "normal",
            lineHeight: 1.2
        };

        const data = {
            labels: ['Openness', 'Coffee', 'Extroversion', 'Agreeableness', 'Communication', 'Rationality'],
            datasets: [{
                data: [7, 10, 6, 8, 9, 9],
                backgroundColor: 'rgba(255, 99, 132, 0.7)',
                borderColor: 'rgba(255, 99, 132, 1)',
                color: "#fff",
                borderWidth: 1,
            }],
            color: "#fff",
        };

        const options = {
            plugins: {
                legend: {
                    display: false
                }
            },
            scales: {
                r: {
                    min: 1,
                    max: 10,
                    grid: {
                        color: "rgba(255,255,255,0.2)"
                    },
                    angleLines: {
                        display: false
                    },
                    pointLabels: {
                        font: {
                            size: 16
                        }
                    },
                    ticks: {
                        display: false,
                    },
                },
            },
            elements: {
                line: {
                    backgroundColor: "#fff",
                    borderWidth: 3
                }
            }
        };

        return (
            <>
                <Row style={{rowGap: "15px"}}>
                    <h1 className = "section-header mb-0"> My skills, visualized </h1>
                    <Col md = { 6 }>
                        <div className = "card-glassmorphism mt-4 h-100">
                            <h2> Personality </h2>
                            <div className = "radar-container m-auto">
                                <Radar redraw = { true } data = { data } options = { options }/>
                            </div>
                        </div>
                    </Col>
                    <Col md = { 6 }>
                        <div className = "card-glassmorphism mt-4 h-100 d-flex flex-column">
                            <h2> Languages </h2>
                            <div className="m-auto w-100 d-flex flex-column justify-content-center h-100">
                                <img src={skills} className="w-100" alt="Python, C/C++, JavaScript, React..." />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col md = {5}>
                        <div className = "card-glassmorphism mt-4 h-100">
                            <h2> Platforms I use </h2>
                            <div className = "terminal">
                                <p><span>giuseppe@giuseppe-pc:~$</span> list --platforms</p>
                                <p>> Linux <small>(btw I use Arch)</small></p>
                                <p>> Amazon Web Services</p>
                                <p>> Raspberry PI</p>
                                <p>> Arduino</p>
                                <p><span className="flashing-cursor">█</span> </p>
                            </div>
                        </div>
                    </Col>
                    <Col md = {7}>
                        <div className = "card-glassmorphism mt-4 h-100">
                            <h2> Tools & Frameworks </h2>
                            <div className = "tools-frameworks d-flex flex-wrap">
                                {badges_json.map((badge, index) => {
                                        return (
                                            <div key = {index} className = "badge-technology m-2">
                                                <img src={badge.url} alt={badge.name} />
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
            </>
        );
    }
}

export default Charts;