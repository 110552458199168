import React, {Component, useState} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from "swiper/core";
import { EffectCards } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "swiper/css/effect-cards";
import ProjectCard from "./ProjectCard";
import "./Project.css"
import steduProject from "./assets/steduProjectThumbnail.png"
import backtestProject from "./assets/backtesterThumbnail.png"
import necstudioProject from "./assets/necstudioProjectThumbnail.png"
import CUPPinoProject from "./assets/CUPPinoThumbnail.png"
import techseumProject from "./assets/techseumProjectThumbnail.png"
import continuousReuseResearch from "./assets/continuousReuseProjectThumbnail.png"
import retinopathyResearch from "./assets/retinopathyResearch.png"
import halideResearch from "./assets/halideProjectThumbnail.png"
import {Container, Row, Col} from "react-bootstrap";
import { CSSTransition } from "react-transition-group";

class Projects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projectsSwiperIndex: 0,
            researchSwiperIndex: 0
        };
    }

    render() {
        const projectsDescription = [
            <div>
                <p>
                    I built a platform to help high-school students that are preparing for the university admission test in Italy.
                    It offers a collection of explained exercises, online courses, community features, and a dashboard to track the progress.
                </p>
                <hr />
                <p>
                    I built it with a mix of Django, Moodle, and different services called with REST APIs.
                    Among these services there is the OpenAI API, which I used to generate custom feedback on the attempts.
                </p>
            </div>,
            <div>
                <p>
                    A tool for one of the teams of an Italian bank to backtest the performance of custom portfolios.
                </p>
                <p>
                    It allows to backtest portfolio returns, risk characteristics, style exposures, drawdowns, and more,
                    while also comparing the time evolution of different portfolios.
                </p>
                <hr />
                <p>
                    Built with Dash, a Python framework for building analytical web applications, and perfect occasion to
                    learn more about finance and investment strategies.
                </p>
            </div>,
            <div>
                <p>A laboratory at my university needed a customized dashboard to deal with the events they organize and
                    to make communication among its member easy. So me and two other students built it!</p>
                <hr />
                <p>
                    Under the supervision of a PhD student, we designed the full-stack architecture of the dashboard,
                    and put our software engineering skills in practice.
                </p>
                <p>
                    Fun fact: this is the project that made me fall in love with ReactJS and Docker!
                </p>
            </div>,
            <div>
                <p>This project consists of a progressive web-app used to interactively visit a technological museum. It features QR code scanning, </p>
                <p>I did not do this alone: I was part of the team that led the development of the webapp.</p>
                <hr />
                <p>
                    We couldn't use any web framework for this project, so we built our own MVC model: it was hard and maybe not the best in the world,
                    but it was educational to say the least!
                </p>
            </div>,
            <div>
                <p>After I explored a little bit the cybersecurity field, I was amazed by how vulnerable many people are to hacker attacks because of
                poorly-managed and easily-guessable password. </p>
                <p>With the humble goal of spreading awareness, I built a service that tells you if your password is vulnerable to a classic dictionary attack.</p>
                <hr />
                <p>
                    Being this a personal project, I didn't have any technical requirements. I took the opportunity to
                    learn a new technology, and built this service entirely in Python with Flask!
                </p>
            </div>,
        ];

        const researchDescription = [
            <div>
                <p>
                    Reproducibility is a key aspect of scientific research. This project is a system that periodically
                    runs particle physics data analyses in their original computing environment, to ensure that they are
                    accessible over time.
                </p>
                <hr />
                <p>
                    With my team at CERN, we wrote a paper on this topic to present at the PV2023 conference.
                    You can read it <a href="https://doi.org/10.5281/zenodo.10263204">here</a>!
                </p>
            </div>,
            <div>
                <p>Diabetic retinopathy is among the leading causes of blindness in the world. Early diagnosis is crucial, but it's hard.</p>
                <p>
                    At IIT in Chicago, a tool for early diagnosis starting from images of fluorescein angiography had been developed, but unfortunately, patients often
                    blink and cover their eyes during the exam, making the images unsuitable for the analysis. I developed a tool
                    to automatically detect these bad images and fix the video of the angiography, where possible, to not compromise the diagnosis.
                </p>
                <hr />
                <p>
                    A first attempt was made using MATLAB, but it was almost completely manual and too slow. I improved it, and made it fully automatic.
                    I had to learn a lot of math for image processing in order to be able to detect efficiently the portion of the images
                    that were bad, but it was fun!
                </p>
            </div>,
            <div>
                <p>This project is part of a bigger computer vision tool developed by Eleonora D'Arnese, that aims at identifying automatically lung cancers from CT & PET scans.</p>
                <p>In particular, I had to create a software that, given CT scans of the lungs, prepared the images for further analysis by segmenting (isolating) the lungs. </p>
                <hr />
                <p>
                    To improve the speed of the software, I used Halide, which is a highly-performant, low-level C++ library for computer vision.
                    Learning how to use it wasn't easy at all, but I'm proud of having been able to understand it on my own!
                </p>
            </div>,
        ];

        return (
            <div>
                <h1 className="section-header">Some of my projects</h1>
                <Container>
                    <Row>
                        <Col lg={7}>
                            <Swiper
                                modules={[EffectCards, Pagination, Navigation]} effect="cards"
                                slidesPerView={1}
                                centeredSlides={true}
                                onSlideChange={s => this.setState({ projectsSwiperIndex: s.realIndex })}
                                pagination={{
                                    clickable: true,
                                }}
                                navigation={{
                                    nextEl: ".swiper-button-next",
                                    prevEl: ".swiper-button-prev",
                                    enabled: true
                                }}
                            >
                                <SwiperSlide>
                                    <ProjectCard title="STEdu"
                                                 description="Web Development: Django, Moodle, Docker, OpenAI"
                                                 imgsrc={steduProject}
                                                 url="https://online.stedu.it"
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <ProjectCard title="Portfolio backtesting"
                                                 description="Web Development: Python, Dash"
                                                 imgsrc={backtestProject}
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <ProjectCard title="NECSTudio"
                                                 description="Web Development: ReactJS, NodeJS, Docker"
                                                 imgsrc={necstudioProject}
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <ProjectCard title="TechSeum"
                                                 description="Web Development: PHP, HTML, CSS"
                                                 imgsrc={techseumProject}
                                                 url="https://www.isdimaggio.it/techseum/"
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <ProjectCard title="CUPPino"
                                                 description="Python, Flask"
                                                 imgsrc={CUPPinoProject}
                                                 url="https://giuseppesteduto.me/cuppino"
                                    />
                                </SwiperSlide>
                            </Swiper>
                        </Col>
                        <Col lg={5}>
                            <div className="project-description">
                                <h3>Description</h3>
                                <CSSTransition
                                    timeout={500}>
                                    <div key={this.state.projectsSwiperIndex}>
                                        {projectsDescription[this.state.projectsSwiperIndex]}
                                    </div>
                                </CSSTransition>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <h1 className="section-header mt-5">Cool research I worked on</h1>
                <Container>
                    <Row>
                        <Col lg={5}>
                            <div className="project-description">
                                <h3>Description</h3>
                                <CSSTransition
                                    timeout={500}>
                                    <div key={this.state.researchSwiperIndex}>
                                        {researchDescription[this.state.researchSwiperIndex]}
                                    </div>
                                </CSSTransition>
                            </div>
                        </Col>
                        <Col lg={7}>
                            <Swiper
                                modules={[EffectCards, Pagination, Navigation]} effect="cards"
                                slidesPerView={1}
                                centeredSlides={true}
                                onSlideChange={s => this.setState({ researchSwiperIndex: s.realIndex })}
                                pagination={{
                                    clickable: true,
                                }}
                                navigation={{
                                    nextEl: ".swiper-button-next",
                                    prevEl: ".swiper-button-prev",
                                    enabled: true
                                }}
                            >
                                <SwiperSlide>
                                    <ProjectCard title="Continuous Reuse"
                                                 description="A paper on reproducible science"
                                                 imgsrc={continuousReuseResearch}
                                                 url="https://doi.org/10.5281/zenodo.10263204"
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <ProjectCard title="Retinopathy detection from angiography"
                                                 description="MATLAB, Python, and a lot of math "
                                                 imgsrc={retinopathyResearch}
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <ProjectCard title="Lung cancer detection with Halide"
                                                 description="Computer vision: C++, Halide"
                                                 imgsrc={halideResearch}
                                    />
                                </SwiperSlide>
                            </Swiper>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

Projects.propTypes = {};

export default Projects;